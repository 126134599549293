<template>
  <div class="shop">
    <!-- 搜索 -->
    <van-sticky :offset-top="0">
      <div>
        <van-search
          v-model="search"
          placeholder="请输入队伍名称"
          @search="onSearch"
        >
          <template #left-icon>
            <img
              src="https://lyxnyapi.dexian.ren//upload/images/2022/7/cfe5e07a-158.png"
              alt=""
              width="14"
              height="14"
            />
          </template>
        </van-search>
      </div>
      <!-- <van-tabs
        @click="onClickOnlyOne"
        color="#f63e3b"
        title-active-color="#f63e3b"
      >
        <van-tab name="全部" title="全部"> </van-tab>
        <van-tab
          v-for="item in CateList"
          :key="item.CateId"
          :name="item.CateId"
          :title="item.Name"
        >
        </van-tab>
      </van-tabs> -->
    </van-sticky>
    <!-- tab栏 -->
    <div class="information">
      <!-- 全部 -->
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
        :immediate-check="false"
        finished-text="没有更多了"
        :offset="100"
      >
        <!-- <div class="center">111</div> -->
        <div class="center" v-for="item in list" :key="item.index">
          <router-link :to="'/huayang/index/' + item.TeamId">
            <van-card style="padding: 12px; background-color: white">
              <template #thumb>
                <img
                  v-if="!item.Img"
                  style="width: 100%; height: 100%"
                  src="http://alycommunity.anluoyun.cn/lc-lcxms/2023/11/d3fba16f-a35.png"
                />
                <img
                  v-else
                  style="width: 100%; height: 100%"
                  :src="item.Img.split(',')[0]"
                />
              </template>
              <template #title>
                <div
                  class="centerTitle van-ellipsis"
                  style="width: 100%; padding-bottom: 6px"
                >
                  {{ item.Name }}
                </div>
              </template>

              <template #tags>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 65%;
                  "
                >
                  <div
                    style="
                      padding-bottom: 2px;
                      font-size: 13px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #999999;
                    "
                  >
                    <van-icon
                      name="http://alycommunity.anluoyun.cn/mj-jljd/2023/11/cce14aa0-b30.png"
                    />
                    队长：{{ item.Legaler }}
                  </div>
                  <div
                    style="
                      padding-bottom: 2px;
                      font-size: 13px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #999999;
                    "
                  >
                    <van-icon
                      name="http://alycommunity.anluoyun.cn/mj-jljd/2023/11/e21aac96-379.png"
                    />
                    队伍编号：{{ item.Code }}
                  </div>
                  <div
                    style="
                      font-size: 13px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #999999;
                    "
                  >
                    <van-icon
                      name="http://alycommunity.anluoyun.cn/mj-jljd/2023/11/591913d2-404.png"
                    />
                    求助电话：{{ item.HelpTel }}
                  </div>
                </div>
              </template>
              <template #footer>
                <van-button type="default">查看详情</van-button>
              </template>
            </van-card>
          </router-link>
        </div>
      </van-list>
      <!-- 没有更多数据了 -->
      <!-- <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div> -->
    </div>
  </div>
</template>
<script>
import { WxGetTeamPage, WxGetProjectCateList } from "@/api/RealInfo";
export default {
  data() {
    return {
      CateList: [],
      riKind: "", //tab栏
      list: [], //资讯列表
      listfrom: {
        page: 1,
        limit: 10,
      }, //查询传参
      search: "", //搜索内容
      hidden: false, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  methods: {
    // 获取资讯分类
    async getInfoKindInfoPage() {
      const res = await WxGetProjectCateList();
      console.log(res, "res");
      this.CateList = res.data.data;
      console.log(this.CateList, "this.CateList");
    },
    onClickOnlyOne(name, title) {
      console.log(name);
      this.riKind = name;
      this.listfrom.page = 1;
      this.getList();
    },
    // 获取分页列表
    getList() {
      // this.listfrom.cateId = this.riKind;
      this.listfrom.name = this.search;
      WxGetTeamPage(this.listfrom)
        .then((res) => {
          this.loading = false;
          if (res.data.code == 0) {
            this.total = res.data.count;
            let rows = res.data.data; //请求返回当页的列表
            // 加载状态结束

            if (rows == null || rows.length === 0) {
              // 加载结束
              console.log("结束");
              this.finished = true;
              return;
            }
            // 将新数据与老数据进行合并
            this.list = this.list.concat(rows);
            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.list.length >= res.data.count) {
              this.finished = true;
            }

            // this.loading = false
            // if (res.data.count == 0) {
            //   this.list = {};
            // } else {
            //   this.list = res.data.data;
            //   for (var i = 0; i < this.list.length; i++) {
            //     if (this.list[i].AImgUrl == "") {
            //       this.list[i].AImgUrl =
            //         "https://lyxnyapi.dexian.ren//upload/images/2022/7/d0e8c771-cd6.png";
            //     }
            //   }
            // }
          } else {
            this.finished = true;
          }
        })
        .catch(() => {});
    },
    // 下拉刷新
    onLoad() {
      console.log(123);
      this.loading = true;
      this.listfrom.page = this.listfrom.page + 1;
      this.getList();
    },
    // tan点击切换事件
    // onClick(e) {
    //   this.riKind = e;
    //   this.listfrom.page = 1;
    //   this.getList();
    // },
    // 搜索
    onSearch() {
      this.listfrom.page = 1;
      this.list = [];
      this.getList();
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted() {
    this.getList();
    // this.getInfoKindInfoPage();
  },
};
</script>
<style scoped>
.shop {
  background: #f8f8f8;
  min-height: 100vh;
}
.van-card__thumb {
  margin-right: 11px;
  width: 85px;
  height: 85px;
}
.shopTitle {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
  /* background: rgb(246, 243, 243); */
}
.centertime {
  font-size: 14px !important;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
/deep/.van-field__left-icon {
  margin-right: 6px;
  margin-top: 2px;
}

/deep/ .van-card {
  border-radius: 8px;
}

.centerTitle {
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #1b2024;
  padding-top: 4px;
}

/deep/ .van-icon__image {
  width: 1.2em;
  height: 1.2em;
}

/deep/ .van-icon {
  vertical-align: middle;
  margin-right: 6px;
}

/deep/ .van-card__footer {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

/deep/ .van-button {
  height: 24px;
  padding: 0 8px;
  font-size: 11px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}

/deep/ .van-button--default {
  border: 1px solid #dcdcdc !important;
  border-radius: 12px !important;
}
.essay {
  padding-bottom: 50px;
}
</style>
